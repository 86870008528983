<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="lump bo_zero">
            <label class="input srh_type02">
              <input
                style="border: 1px solid #DBDBDB; border-right: unset;"
                type="search"
                placeholder="검색어를 입력해 주세요."
                @keypress.enter="fnSearch"
                @input="inputSearchText"
                :value="searchText"
              />
              <button
                style="border-top: 1px solid #DBDBDB; border-bottom: 1px solid #DBDBDB;"
                v-if="searchText"
                class="btn_search delete"
                @click="() => (searchText = '')"
              ></button>
              <button
                style="border: 1px solid #DBDBDB; border-left: unset;"
                class="btn_search"
                @click="fnSearch"
              >
                검색
              </button>
            </label>
          </div>
          <div class="notice_list_wrap">
            <div class="list_top">
              <div class="left">
                <div class="total">총 {{ totalCnt }}건</div>
              </div>
              <div class="right"></div>
            </div>
            <div class="empty_wrap" v-if="totalCnt < 1">
              <div class="empty">
                <div>
                  검색된 결과가 없습니다.
                </div>
              </div>
            </div>
            <div class="notice_list" v-else>
              <ul class="notice_list_ul">
                <li
                  class="notice_list_li"
                  v-for="(item, index) in list"
                  :key="index"
                  @click="fnView(item)"
                >
                  <div class="notice_list_ttl">
                    <div class="left">
                      <span
                        class="cate"
                        :class="setNoticeTypeClassName(item.noticeType)"
                        >{{ item.noticeType }}</span
                      >
                      <span>{{ item.title }}</span>
                    </div>
                    <div class="right"></div>
                  </div>
                  <div class="notice_list_con">
                    <div class="left">
                      <div class="date">{{ item.regDt }}</div>
                    </div>
                    <div class="right"></div>
                  </div>
                </li>
              </ul>
            </div>
            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-cs-notice-index',
  setup() {
    const state = componentState()
    return { ...state }
  }
}
</script>

<style scoped></style>
